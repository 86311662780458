import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import homeActions from '../../redux/home/actions';
import checkOutActions from '../../redux/checkOut/actions';
import getProfileActions from '../../redux/getProfile/actions';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Link } from 'react-router-dom';
import Loading from '../Loader/Loading';
import Swal from 'sweetalert2';
import './checkOutNew.css';
import 'sweetalert2/dist/sweetalert2.css';
import { CloseOutlined } from '@ant-design/icons'
import { Button, Radio, Collapse, Image, Form, Checkbox, Input, Popconfirm, Tooltip } from 'antd';
import noImageFound from '../../assets/image/no-image-found.png';

function CheckOut() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [paymentGatewayId, setpaymentGatewayId] = useState(0);
  const [currentOrderId, setCurrentOrderId] = useState(0);
  const { getPlanByReferenceURL, validateToken, resetHomePage } = homeActions;
  const { getProfileData } = getProfileActions;
  const { createOrderRequest, sendOrderDataRequest, resetcreateOrderRequest, getPaymentGateway, applyCoupon, resetCoupon, resetCouponMessage } = checkOutActions;
  const planDetailsResult = useSelector((state) => state.homeReducer.planDetailsResult);
  const { applyCouponResult, applyCouponLoading, applyCouponError, isCouponApplied } = useSelector((state) => state.checkOutReducer);
  const loading = useSelector((state) => state.homeReducer.loading);
  const tokenError = useSelector((state) => state.homeReducer.tokenError);
  const tokenMessage = useSelector((state) => state.homeReducer.tokenMessage);
  const OrderId = useSelector((state) => state.checkOutReducer.OrderId);
  const OrderNumber = useSelector((state) => state.checkOutReducer.OrderNumber);
  const CronusData = useSelector((state) => state.checkOutReducer.orderData);
  const paymentGateway = useSelector((state) => state.checkOutReducer.paymentGateway);
  const checkOutReducerLoading = useSelector((state) => state.checkOutReducer.paymentLoader);
  const [token, setToken] = useState(null)
  const [paypalButton, setPaypalButton] = useState(true)
  const [newOrderId, setNewOrderId] = useState("");
  const newOrderIdRef = useRef(null);
  const [editCheck, setEditCheck] = useState(false);
  const [couponCodeFlag, setcouponCodeFlag] = useState(false);
  const [couponFormData, setCouponFormData] = useState({
    couponCode: '',
    totalPrice: ''
  })
  // const [showCouponFlag, setShowCouponFlag] = useState(false);
  // const [isCouponApplied, setisCouponApplied] = useState(false);
  // const successMessage = useSelector((state) => state.checkOutReducer.applyCouponMessage);
  // const errorMessage = useSelector((state) => state.checkOutReducer.applyCouponError);


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetHomePage());
    const url = window.location.href;
    const parts = url.split('/');
    const referenceURl = parts[parts.length - 1];
    dispatch(getPlanByReferenceURL(referenceURl));
    setToken(null);
    dispatch(resetcreateOrderRequest());
    PaypalButton()
    dispatch(getPaymentGateway());
  }, []);

  useEffect(() => {
    setNewOrderId(OrderId);
  }, [OrderId]);

  useEffect(() => {
    newOrderIdRef.current = newOrderId;
  }, [newOrderId]);

  useEffect(() => {
    dispatch(validateToken());
  }, []);
  useEffect(() => {
    dispatch(resetCoupon());
  }, []);


  useEffect(() => {
    if (applyCouponResult && applyCouponResult.length > 0 && applyCouponResult.CouponCode) {
      setCouponFormData(pre => ({
        ...pre,
        couponCode: applyCouponResult.CouponCode
      }))
    }
    else {
      setCouponFormData(pre => ({
        ...pre,
        couponCode: ''
      }))
    }

  }, [applyCouponResult])

  const onApprove = async (data, actions) => {
    const transactionDetails = await actions.order.capture();
    const obj = {
      orderID: newOrderIdRef.current,
      PreferedAggregator: planDetailsResult[0].PreferedAggregator,
      ESimGoTrafficPolicyID: planDetailsResult[0].ESimGoTrafficPolicyID,
      MayaPlanUID: planDetailsResult[0].MayaPlanUID,
      CountryISO2: planDetailsResult[0].CountryISO2,
      paypalResponse: transactionDetails,
    }

    dispatch(sendOrderDataRequest(obj));
  }

  const createOrder = (data, actions) => {
    if (currentOrderId !== 0) {
      return currentOrderId;
    }

    const obj = {
      grandTotal: planDetailsResult[0].PlanPrice,
      couponDiscount: applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].CouponDiscount ? applyCouponResult[0].CouponDiscount : 0,
      netAmount: applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].TotalAmountPrice ? applyCouponResult[0].TotalAmountPrice : planDetailsResult[0].PlanPrice,
      planId: planDetailsResult[0].ID,
      isCouponApplied: isCouponApplied ? 1 : 0,
      couponCode: applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].CouponCode ? applyCouponResult[0].CouponCode : '',
      couponID: applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].ID ? applyCouponResult[0].ID : '',
    };
     dispatch(createOrderRequest(obj));
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Purchase Sim",
            amount: {
              currency_code: "USD",
              value: applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].TotalAmountPrice ? applyCouponResult[0].TotalAmountPrice : planDetailsResult[0].PlanPrice
            }
          }
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING"
        }
      })
      .then((orderID) => {
        setCurrentOrderId(orderID);
        return orderID;
      })
      .catch((error) => {
        const errorMessage = error.message;
        const responseObject = JSON.parse(errorMessage.substring(errorMessage.indexOf("{")));
        const obj = {
          orderID: newOrderIdRef.current,
          PreferedAggregator: planDetailsResult[0].PreferedAggregator,
          MayaPlanUID: planDetailsResult[0].MayaPlanUID,
          CountryISO2: planDetailsResult[0].CountryISO2,
          paypalResponse: responseObject,
        }
        dispatch(sendOrderDataRequest(obj));
      });
  };


  const cancel = (text) => {
    const obj = {
      orderID: newOrderIdRef.current,
      PreferedAggregator: planDetailsResult[0].PreferedAggregator,
      MayaPlanUID: planDetailsResult[0].MayaPlanUID,
      CountryISO2: planDetailsResult[0].CountryISO2,
      paypalResponse: {
        "name": "PAYMENT_CANCELLED",
        "message": "payment_cancel_by_user"
      }
    }
    dispatch(sendOrderDataRequest(obj));
    // Swal.fire({
    //   position: 'center',
    //   icon: 'error',
    //   title: `Payment Cancelled`,
    //   // text: `${text} check your email`,
    //   showConfirmButton: true,
    //   customClass: {
    //     popup: 'custom-popup-className',
    //     title: 'custom-title-className',
    //     confirmButton: 'custom-button-className',
    //   },
    // });
    // setCheckBox(true)
    setPaypalButton(true)
  };


  const handleBackToShop = () => {
    dispatch(resetcreateOrderRequest());
    history.push('/shop');
  }

  const handleAcceptAgreement = () => {
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    setPaypalButton(false);
    if (tokenError || token === null) {
      history.push('/login')
    }
  }

  const PaypalButton = () => {
    let payPalCredencials = paymentGateway && paymentGateway.length > 0 && paymentGateway.filter((item) => item.ID == 1);
    payPalCredencials = payPalCredencials && JSON.parse(payPalCredencials[0].meta_data)[0];
    return (
      <>
        <PayPalScriptProvider options={{
          // clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID
          clientId: payPalCredencials.PAYPAL_CLIENT_ID
        }}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={(err) => console.log(err)}
            onCancel={cancel}
            style={{ layout: "vertical", color: "silver", shape: "rect" }}
          />
        </PayPalScriptProvider>

      </>
    );
  }

  const paymentG = paymentGateway.map((item, index) => {
    return {
      key: item.ID,
      label: <h6>{item.PaymentGatewayName}</h6>,
      children: false,
      extra: <img src={item.LogoUrl} style={{ height: '40px' }} />,
      showArrow: false,
    }
  });

  const handlePaymentGatewayChange = (key) => {
    if (key.length === 0) {
      return false;
    }
    setpaymentGatewayId(key[0]);
  }

  const handleRemoveCoupon = () => {
    dispatch(resetCoupon())
    form.resetFields();
  }

  const onFinish = (data) => {
    delete data.applyCoupon;
    data.totalPrice = planDetailsResult && planDetailsResult.length > 0 && planDetailsResult[0].PlanPrice
    if (couponFormData.couponCode == '' || couponFormData.couponCode == null || couponFormData.couponCode == undefined) {
      dispatch(resetCouponMessage())
    }
    else {
      dispatch(applyCoupon(couponFormData));
    }
  }
  const handleChangeCouponInput = (e) => {
    const value = e.target.value
    setCouponFormData(pre => ({
      ...pre,
      couponCode: value,
      totalPrice: planDetailsResult && planDetailsResult.length > 0 && planDetailsResult[0].PlanPrice ? planDetailsResult[0].PlanPrice : '0'
    }))
  }

  const handleClose = () => {
    dispatch(resetCouponMessage());
  }

  // const error = (text) => {
  //   Swal.fire({
  //     position: 'center',
  //     icon: 'error',
  //     title: `${text}`,
  //     // text: `${text}`,
  //     showConfirmButton: true,
  //     customClass: {
  //       popup: 'custom-popup-class',
  //       title: 'custom-title-class',
  //       confirmButton: 'custom-button-class',
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       handleClose();
  //     }
  //   });
  // };

  // const success = (text) => {
  //   Swal.fire({
  //     position: 'center',
  //     icon: 'success',
  //     title: `Success`,
  //     text: text,
  //     showConfirmButton: true,
  //     customClass: {
  //       popup: 'custom-popup-class',
  //       title: 'custom-title-class',
  //       confirmButton: 'custom-button-class',
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       if (successMessage !== null) {
  //       }
  //       handleClose();
  //     }
  //   });
  // };

  return (
    <>
      {CronusData.Status === 1 ? <div className="paper-box">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="printer-top"></div>

            <div className="paper-container">
              <div className="printer-bottom"></div>

              <div className="paper">
                <div className="main-contents">
                  <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_vuliyhde.json" background="transparent" speed="1" style={{ width: "auto", height: "150px" }} autoplay></lottie-player>

                  <div className="success-title">
                    Order Complete
                  </div>
                  <div className="success-description">
                    Your Order has been Successfully Placed.
                  </div>
                  <div className="payment-popup">
                    <div className="order-number-label">Order Number</div>
                    <div className="order-number">{OrderNumber}</div>
                  </div>
                  <div className="order-footer"><button className='rtr-btn' onClick={handleBackToShop}>Back to Shop</button></div>
                </div>
                <div className="jagged-edge"></div>
              </div>
            </div>

          </div>
        </div>

      </div> : CronusData.Status === 0 && (CronusData.ErrorCode == null || CronusData.ErrorCode == undefined) ? <div className="paper-box">
        <div className="printer-top"></div>

        <div className="paper-container">
          <div className="printer-bottom"></div>

          <div className="paper">
            <div className="main-contents">

              <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qpwbiyxf.json" background="transparent" speed="1" autoplay style={{ width: "auto", height: "100px" }}></lottie-player>

              <div className="error-title">
                Order Failed
              </div>
              <div className="success-description">
                Your order has failed due to a technical issue
              </div>
              <div style={{ textAlign: "center", color: "#356fad", fontWeight: 600, fontSize: "16px", paddingBottom: "6px" }}>
                Contact to Admin
              </div>
              <p style={{ "color": "#999", fontSize: "8px", padding: 0, margin: 0, paddingBottom: "6px" }}><b>Note :- </b>The deducted amount will be refunded within 5-7 business days. for any query reach out us. <Link to='/contact-us' style={{ fontSize: "12px" }}>click</Link></p>

              <div className="order-footer">
                <button className='bck-btn' onClick={handleBackToShop} >Back to Shop</button>
                {/* <button className='rtr-btn' onClick={() => dispatch(resetcreateOrderRequest())}>Retry</button> */}
              </div>
            </div>
            <div className="jagged-edge"></div>
          </div>
        </div>
      </div> :
        <section className="check-sec">
          {loading && <Loading />}
          {planDetailsResult && planDetailsResult.length > 0 && planDetailsResult.map((select, index) => {
            const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${select.ImageUrl}`;
            return (
              <div className="container" key={index}>
                <div className="row justify-content-center g-2">

                  <div className="col-lg-8 col-md-12 col-sm-12 ">
                    <div className="card-detail-new">
                      <div className=' row card-inner-panel'>
                        <div className='col-lg-6 col-md-6'>
                          <div className="card-main-new">
                            <div className="card-detail-img-new">
                              {/* <img src={imagesvg} alt="" className="img-fluid rounded" /> */}
                              <Image src={imagesvg} preview={false} className="img-fluid rounded" fallback={noImageFound} />
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 col-md-6'>
                          <div className="card-detail-price-new">
                            <h1 class="ribbon-header">
                              <strong class="ribbon-content">Canada 30GB 7Days Testing 3</strong>
                            </h1>
                            {/* {<p className='plan-name-new'>{select.PlanName}</p>} */}
                            <div className='fancy-new-card'>
                              <div className="header-new">
                                <span className='data-text-new'>Data</span>
                                <span className='data-num-new'>{select.PlanDataValue}</span>
                              </div>

                              <div className='total-amout-new'>
                                <span className='data-text-new'>Validity</span>
                                <span className='data-num-new' >{select.PlanDaysValue}</span>
                              </div>

                              <div className='total-amout-new'>
                                <span className='data-text-new'>Price</span>
                                <span className='data-num-new' >${select.PlanPrice}</span>
                              </div>
                            </div>
                          </div>
                        </div>



                      </div>

                      <div className='row py-3'>
                        {/* <h5 className='order-summary-new'>Choose Payment Method</h5> */}
                        {/* <div className='col-lg-8 col-md-8 col-sm-12'>
                          <div className="mb-3">
                            <input type="text" className="form-control gift-card-new" id="exampleFormControlInput1" placeholder="Gift Card Or Discount Code" />
                          </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12'>
                          <div className="mb-2">
                            <button className='apply-copun-new'>Apply</button>
                          </div>
                        </div> */}

                      </div>

                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Form initialValues={couponFormData} onFinish={onFinish} form={form}>
                          <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', }}>
                              <Form.Item label="Coupon Code" onChange={handleChangeCouponInput} name={couponCodeFlag ? "couponCode" : ''} >
                                <Input placeholder="Coupon Code" defaultValue={couponFormData.couponCode} disabled={isCouponApplied ? true : false} />
                                {applyCouponError ? <p style={{ color: "red" }}>{applyCouponError}</p> : ''}
                                {isCouponApplied ?
                                  <div style={{ display: 'flex', marginTop: '10px', gap: "5px" }}>
                                    <div style={{ width: '17px', height: '17px', borderRadius: '50%', backgroundColor: '#008000', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', marginRight: '8px', textAlign: 'end', }} >
                                      ✓
                                    </div>
                                    <span style={{ fontStyle: 'italic', color: "#1dace7", marginLeft: '-7px', marginTop: '-2px' }}>Coupon Applied!</span>
                                  </div> : ''}
                              </Form.Item>


                              {!isCouponApplied ?
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                  <Button type="primary" style={{ backgroundColor: "#f69322" }} loading={applyCouponLoading} htmlType="submit">
                                    APPLY
                                  </Button>
                                </Form.Item> : <div style={{ display: 'flex', alignItems: 'center', marginTop: '14px', height: '30px', marginLeft: '15px', cursor: 'pointer' }}>
                                  <Popconfirm
                                    title="Are you sure you want to remove coupon?"
                                    okText="Yes"
                                    cancelText="No"
                                    placement="topLeft"
                                    onConfirm={() => handleRemoveCoupon()}
                                  >
                                    <span style={{ width: '26px', height: '26px', textAlign: 'center', color: 'white', background: 'red', borderRadius: "50%" }} title="Remove Coupon" > <CloseOutlined /> </span>
                                  </Popconfirm>
                                </div>
                              }
                            </div>
                          </div>
                        </Form>
                      </div>

                      <div className='row py-3'>
                        <div className='col-lg-12 col-md-12 col-sm-12 ttt-p '>
                          <span style={{ 'fontSize': '15px', fontWeight: "700" }}>Total</span>
                          <span style={{ 'fontSize': '15px', fontWeight: "700" }}>${select.PlanPrice.toFixed(2)}</span>
                        </div>
                        {applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].CouponDiscount ? <div className='col-lg-12 col-md-12 col-sm-12 ttt-p '>
                          <span style={{ 'fontSize': '15px', fontStyle: 'italic' }} >Coupon Discount<span style={{ color: "grey", fontSize: "13px" }}>(Coupon Code : </span><span style={{ color: "#f8921f", fontSize: "12px" }}>{(applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].CouponCode)})</span></span>
                          <span style={{ 'fontSize': '15px', 'color': 'red', fontStyle: 'italic' }} >-${applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].CouponDiscount ? applyCouponResult[0].CouponDiscount.toFixed(2) : '0.00'}</span>
                        </div> : ''}
                        {applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].TotalAmountPrice ? <div className='col-lg-12 col-md-12 col-sm-12 ttt-p '>
                          <span style={{ 'fontSize': '15px', fontWeight: "700" }}>Net Total</span>
                          <span style={{ 'fontSize': '15px', 'color': 'green', fontWeight: "700" }}>${applyCouponResult && applyCouponResult.length > 0 && applyCouponResult[0].TotalAmountPrice ? applyCouponResult[0].TotalAmountPrice.toFixed(2) : select.PlanPrice.toFixed(2)}</span>
                        </div> : ''}

                      </div>

                      <div className='row'>
                        <h5 className='order-summary-new'>Choose Payment Method</h5>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                          {/* <div className="form-check form-check-new"> */}
                          {/* <input className="form-check-input new-check" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => handleAcceptAgreement(e)} /> */}
                          {/* <label className="form-check-label form-check-label-new" htmlFor="flexCheckDefault">
                              Almost There - Just One More Step
                            </label> */}
                          {/* </div> */}

                          {/* <Radio.Group defaultValue="1" buttonStyle="solid">
                            {paymentGateway.map((item, index) => {
                              return <Radio.Button key={index} value={item.ID}>{item.PaymentGatewayName}</Radio.Button>
                            })}
                          </Radio.Group> */}
                          {paymentGatewayId == 0 ? <Collapse accordion items={paymentG} onChange={handlePaymentGatewayChange} /> : ''}
                        </div>
                      </div>


                      <div className='row py-3'>

                        {checkOutReducerLoading ? <div className='container'>
                          <div className='paypal-p-main-div'>
                            <span class="paypal-loader"></span>
                          </div>
                          <p style={{ textAlign: 'center', marginTop: '70px' }}>Please wait while we process your payment.</p>
                        </div> : ''}

                        {
                          paymentGatewayId == 1 && !checkOutReducerLoading ? <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
                            <div className='paypal-p-main-div'>
                              <span class="paypal-loader"></span>
                              {PaypalButton()}
                            </div>
                          </div> : ''
                        }

                        {/* <div className='col-lg-12 col-md-12 col-sm-12 text-center'>
                          {
                            paypalButton ? <Button className='complte-order-new' onClick={() => handleAcceptAgreement()}><i class="fas fa-credit-card" style={{ fontSize: "17px" }}></i> <span style={{ marginLeft: "5px", fontSize: "20px" }}>Payment</span></Button>
                              :
                              <div className='paypal-p-main-div'>
                                <span class="paypal-loader"></span>
                                {PaypalButton()}
                              </div>
                          }
                        </div> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

        </section>}
      {/* section 2 */}
    </>
  )
}

export default CheckOut