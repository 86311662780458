import React, { useState } from 'react'
import Carousel from "react-grid-carousel";
import noPlanFoundImg from "../../assets/image/no-data-found.png";
import noImageFound from '../../assets/image/no-image-found.png';
import { Link, useHistory } from "react-router-dom";
import CountryFlag from "react-country-flag";
import { Image } from "antd";
const countryList = require("country-list");


function PlanDetailsCarousel({ planDataResult, planData, loading, heading }) {
    const history = useHistory();
    const searchLoading = false;
    const [dummyData, setDummyData] = useState([1, 2, 3, 4, 5]);
    const getCarouselConfig = () => {
        if (window.innerWidth <= 1200) {
            return tabletConfig; // For tablets
        } else {
            // You can add additional configurations for larger screens if needed
            return deskConfig; // Default to tablet configuration
        }
    };

    const tabletConfig = {
        cols: 2,
        rows: 1,
        gap: 10,
        loop: true,
        autoplay: 1000,

    };

    const deskConfig = {
        cols: 2,
        rows: 1,
        gap: 10,
        loop: true,
        autoplay: 1000,

    };

    const carouselConfig = getCarouselConfig();

    const NavigateToPlanDetailPage = (referenceURL) => {
        history.push(`/plan-details/${referenceURL}`);
    }

    console.log(planDataResult)
    return (
        <>
            <div
                className="modal fade"
                id={`staticModel${planDataResult[0].ID}`}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Supported Countries
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="scroll-bar-country-model">
                                <ul className="ul-country-model">
                                    {planDataResult[0].CountryISO2 &&
                                        planDataResult[0].CountryISO2.length > 0 &&
                                        planDataResult[0].CountryISO2.map((country, index) => {
                                            const countryName = countryList.getName(country);
                                            return (
                                                <li
                                                    key={index}
                                                    className="country-li-flag-name-countymodel"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={countryName}
                                                >
                                                    {planDataResult[0].AvailableForFlag === 20 ? (
                                                        ""
                                                    ) : (
                                                        <span className="bd-flag">
                                                            <CountryFlag
                                                                countryCode={country}
                                                                svg
                                                                style={{
                                                                    width: "39px",
                                                                    height: "41px",
                                                                    borderRadius: "9px",
                                                                    margin: "-6px 0 0 -8px",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row plan-det-car">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h5 className="reg-heading ms-0">{heading}</h5>
                    <Carousel autoplay={4000} scrollSnap={true} cols={carouselConfig.cols} rows={carouselConfig.rows} gap={carouselConfig.gap} hideArrow={true} loop={carouselConfig.loop}>
                        {planData && planData.length > 0 ?
                            planData.map((plan, index) => {
                                const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                                return (
                                    <Carousel.Item key={index}>
                                        <div className="col">
                                            <div className="card plan-det-card" >
                                                {plan.FeaturedPlan === 1 && <h1 className="ribbon-plan-Details ribbon-plan-details-carousel" style={{ backgroundColor: "#B70404",  }}>Featured</h1>}
                                                {plan.BestSeller === 1 && <h1 className="ribbon-plan-Details">Best Seller</h1>}
                                                <div className="image-container" onClick={() => NavigateToPlanDetailPage(plan.ReferenceURL)}>
                                                    <Image preview={false} fallback={noImageFound} src={imagesvg} />
                                                </div>
                                                <div className="card-body card-body-1">
                                                    <ul className="ul-margin">
                                                        <li className="bahamas" onClick={() => NavigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                                        <li className="nrth-amrica">
                                                            {plan.PlanDataValue +
                                                                " " +
                                                                plan.PlanDaysValue}
                                                        </li>
                                                    </ul>
                                                    <div className="card-text-2">
                                                        <span className="day-5gb">COVERAGE</span>
                                                        {planDataResult[0].CountryISO2 &&
                                                            planDataResult[0].CountryISO2.length > 1 ? <span
                                                                className="dolor-coverage-Country-homePage india-coverage"
                                                                data-bs-toggle="modal"
                                                                data-bs-target={`#staticModel${planDataResult[0].ID}`}
                                                            >
                                                            {planDataResult[0].CountryISO2 &&
                                                                planDataResult[0].CountryISO2.length && planDataResult[0].CountryISO2.length + " Countries"
                                                            }
                                                        </span> : <span className="dolor-coverage-Country-homepage">
                                                            {planDataResult[0].Country}
                                                        </span>}

                                                    </div>
                                                    <div className="price-text">
                                                        <span className="dolor">
                                                            <sup>$</sup>
                                                            {plan.PlanPrice}
                                                        </span>
                                                        <Link
                                                            to={`/plan-details/${plan.ReferenceURL}`}
                                                            className="shop"
                                                        >
                                                            <i className="fas fa-shopping-cart"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* CountryISO3 Model   */}
                                    </Carousel.Item>
                                );
                            })
                            : loading || searchLoading ?
                                dummyData.map((item, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            <div className="card is-loading" id="card-ab">
                                                <div className="image"></div>
                                                <div className="content">
                                                    <h2></h2>
                                                    <p className="p-2"></p>
                                                    <p></p>
                                                    <p className="p-2"></p>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    )
                                }) : (
                                    <Carousel.Item>
                                        <div className="col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center">
                                            <div className="noPlanFoundImg">
                                                <img src={noPlanFoundImg} alt="" />
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                )}
                    </Carousel>
                </div>
            </div>

        </>

    )
}

export default PlanDetailsCarousel